<template>
  <div class="_about">
    <section class="_section__hero-wrapper">
      <section class="_section__about--intro">
        <div class="_media">
          <picture class="_picture--1">
            <source
              media="(min-width: 1200px)"
              srcset="@/assets/images/about_intro_1-1200.jpg"
            />
            <source
              media="(min-width: 600px)"
              srcset="@/assets/images/about_intro_1-Small.jpg"
            />
            <img
              class="_image"
              src="@/assets/images/about_intro_1-Small.jpg"
              alt="about"
            /> </picture
          ><picture class="_picture--2">
            <source
              media="(min-width: 1200px)"
              srcset="@/assets/images/about_intro_2-1200.jpg"
            />
            <source
              media="(min-width: 600px)"
              srcset="@/assets/images/about_intro_2-Small.jpg"
            />
            <img
              class="_image"
              src="@/assets/images/about_intro_2-Small.jpg"
              alt="about"
            />
          </picture>
        </div>
        <div class="_card--raised">
          <h2 class="_title">Introduction</h2>
          <div class="_paragraphs">
            <p class="_text">
              Kamal Farms is blessed with beauty and charm for a wholesome stay.
              Filled with warmth and comfort in every nook and corner, it is
              built for those who want to fill themselves up with joy and
              laughter. It is a perfect destination to let go and allow nature
              to unravel its magic for a calming experience.
            </p>
            <p class="_text">
              The space lends itself gracefully for get-togethers, events and
              celebrations. Immerse yourself amidst Buddha’s zen, lush greens, a
              serene river, and fresh air. Kamal farms extend themselves to
              families and individuals for a profound experience.
            </p>
          </div>
        </div>
      </section>
    </section>

    <section class="_section__about--place" id="about-location">
      <div class="_media">
        <picture class="_picture">
          <source
            media="(min-width: 1200px)"
            srcset="@/assets/images/about_karjat-1200.jpg"
          />
          <source
            media="(min-width: 600px)"
            srcset="@/assets/images/about_karjat-Small.jpg"
          />
          <img
            class="_image"
            src="@/assets/images/about_karjat-Small.jpg"
            alt="about"
          />
        </picture>
      </div>
      <div class="_card--raised">
        <h2 class="_title">About Karjat</h2>
        <div class="_paragraphs">
          <p class="_text">
            It is very rare to find tourist destinations that are lesser known
            to the public. Approximately 70 kilometers away from Mumbai and 100
            kilometers away from Pune, Karjat is a quieter small town for
            travellers and tourists.
          </p>
          <p class="_text">
            It is surrounded by meadows, trees, rivers, mountains, caves, and
            temples. The Ulhas Valley, Kondana Caves, Kothali Gadh, Pali
            Bhutivali Dam are places worth a short trek. It is a perfect
            location for Kamal farms to host visitors with absolute care so you
            can return home refreshed and recharged in the lap of nature.
          </p>
        </div>
      </div>
    </section>

    <section class="_about__expansion">
      <section class="_section__about--experiences">
        <!-- <GridGallery class="_grid__gallery" :gallery="gallery"></GridGallery> -->
        <ExpansionPanel class="_experience__expansion">
          <div class="_card__custom">
            <h2 class="_title">Experiences / Activities</h2>
            <div class="_paragraphs">
              <p class="_text">
                <span class="_subtitle">Farm Tour:</span>
                The Farm is blessed with organic produce. We take pleasure in
                indulging you into the journey we share with the farm and
                indegenious forest and sharing knowledge about traditional
                methods of farming.
              </p>

              <p class="_text">
                <span class="_subtitle">Birdwatching:</span>
                Often considered as a forgotten hobby, birdwatching can be fun
                for all ages. A knowledgeable stroll around the forest can
                expand our bird vocabulary beyond pigeons and crows.
              </p>

              <p class="_text">
                <span class="_subtitle">Stargazing:</span>
                It is difficult to find more than 7 stars on a usual night in
                cities. But stars often show up in constellations and are
                visible to the naked eye from the hills. Spend an entire night
                stargazing to greet the sun a very good morning.
              </p>

              <!-- <p class="_text">
                <span class="_subtitle">Fireflies:</span>
                We only see fireflies in animated movies but fireflies exist.
                Come summer, we have fireflies lighting the farmhouse with the
                presence. Often blurring the line between magic and reality.
              </p>

              <p class="_text">
                <span class="_subtitle">Barbeque sessions:</span>
                Our small city homes don’t allow us to hold barbeque sessions.
                However, with the abundance of open space that we offer, you can
                have fun barbecuing with your family.
              </p>

              <p class="_text">
                <span class="_subtitle">Swimming:</span>
                Swimming on a summer day is just what one desires. Lazing around
                in the pool calls for an ideal holiday. The lawns are big enough
                for a makeshift swimming pool especially for one of those
                extremely hot summer days.
              </p> -->
              <p class="_text">
                <span class="_subtitle">Lush green lawns:</span>
                Cities don’t offer enough space for people to enjoy a good game
                let alone well maintained lawns. We have a big lawn for an
                afternoon filled with games, banter and laughter.
              </p>
              <p class="_text">
                <span class="_subtitle">Board Games:</span>
                Gone are the days where families reunite over board games.
                Bringing those old days back to life, we have a variety of board
                games for adults and children alike.
              </p>
              <!-- <p class="_text">
                <span class="_subtitle">Pottery:</span>
                Dipping your hands into the clay to shape your own pot is
                supposed to be a calming experience. Wouldn’t it be amazing to
                replace those plastic containers with self made pots and become
                ecofriendly.
              </p> -->
            </div>
          </div>
        </ExpansionPanel>
      </section>

      <section class="_section__about--places">
        <!-- <GridGallery class="_grid__gallery" :gallery="gallery"></GridGallery> -->
        <ExpansionPanel class="_places__expansion">
          <div class="_card__custom">
            <h2 class="_title">Places To Visit</h2>
            <div class="_paragraphs">
              <p class="_text">
                If you are one of those travellers who love sightseeing, Karjat
                has a variety of places that can satisfy the tourist in you.
                From waterfalls, rivers, lakes, mountains, caves, forts to film
                studios and amusement parks, Karjat offers it all.
              </p>
              <p class="_text">
                <span class="_subtitle">Kothaligadh:</span>
                Approximately 14 kms away from Kamal Farms, Kothaligadh, is a
                majestic fort with a panoramic view of the Ulhas valley.
              </p>
              <p class="_text">
                <span class="_subtitle">Garbett Plateau:</span>
                Green from top to bottom during the rainy season, Garbett
                plateau is 2000 ft above sea level. The view from the top is
                breathtaking.
              </p>
              <p class="_text">
                <span class="_subtitle">Bhivpuri Waterfalls:</span>
                During the rainy season, rappelling at Bhivpuri is ideal for
                trekkers. The waterfall is beautiful and definitely worth a
                short trek.
              </p>
              <p class="_text">
                <span class="_subtitle">Kondana Caves:</span>
                Deep-rooted with ancient history, Kondana caves used to serve as
                a home for Buddhist monks. The architecture of these caves is
                worth a trek.
              </p>
              <p class="_text">
                <span class="_subtitle">ND Studios:</span>
                The Nitin Desai Film Studio, offers tours and activities on the
                sets of Oscar winning film Slumdog Millionaire, Jodhaa Akbar and
                much more.
              </p>
              <p class="_text">
                <span class="_subtitle">Adlabs Imagica:</span>
                25 Kms away from Karjat, A fun day at Adlabs imagica is
                exhilarating. This famous amusement park offers arenas and rides
                for people of all ages.
              </p>
              <p class="_text">
                <span class="_subtitle">Matheran:</span>
                21 Kms away from Karjat, Matheran is a comforting hill station
                ideal for family holidays. The hill station has various points,
                perfect for adventurers.
              </p>
            </div>
          </div>
        </ExpansionPanel>
      </section>
    </section>

    <section class="_section__about--form">
      <form @submit.prevent="sendContactForm()">
        <div class="_card__custom" id="contact-form">
          <h2 class="_title--light">We would love to hear from you</h2>
          <div class="_name">
            <label v-if="showLabels" class="_input__label" for="name"
              >Name*</label
            >
            <input
              ref="inputName"
              v-model="name"
              class="_input__text"
              id="name"
              type="text"
              placeholder="Enter your Name"
            />
          </div>
          <!-- <div class="_divider"></div> -->
          <div class="_email">
            <label v-if="showLabels" class="_input__label" for="email"
              >Email Address*</label
            >
            <input
              ref="inputEmail"
              v-model="email"
              class="_input__text"
              id="email"
              type="text"
              placeholder="Enter your Email id"
            />
          </div>
          <!-- <div class="_divider"></div> -->
          <div class="_phone">
            <label v-if="showLabels" class="_input__label" for="phone"
              >Phone Number*</label
            >
            <input
              ref="inputPhone"
              v-model="phone"
              class="_input__text"
              id="phone"
              type="text"
              placeholder="Enter your Phone no."
            />
          </div>
          <!-- <div class="_divider"></div> -->
          <div class="_interest">
            <label v-if="showLabels" class="_input__label" for="interest"
              >Interested in*</label
            >
            <select
              ref="inputInterest"
              v-model="interest"
              class="_input__select"
              id="interest"
            >
              <!-- <option value="-" disabled selected>Interested in...</option> -->
              <option
                v-for="(option, index) in interestOptions"
                :key="index"
                :value="option.value"
                :disabled="index === 0 ? true : false"
                placeholder="Some..."
              >
                {{ index === 0 && showLabels ? "- Select -" : option.text }}
              </option>
            </select>
          </div>
          <div class="_comment">
            <label v-if="showLabels" class="_input__label" for="comment"
              >Comments (if any)</label
            >
            <textarea
              ref="inputComment"
              v-model="comment"
              class="_input__text-area"
              id="comment"
              type="text"
              placeholder="Tell us something about your enquiry. For eg: the no. of guests, tentative dates, etc."
              rows="4"
            ></textarea>
          </div>
          <!-- <div class="_divider"></div> -->
          <label
            ref="responseElement"
            class="_response"
            v-if="submitResponse != ''"
            >{{ this.submitResponse }}</label
          >
          <button
            class="btn--light"
            type="submit"
            :disabled="submitStatus === 1 ? true : false"
            v-if="submitStatus != -1"
          >
            Submit Now
          </button>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import { gsap } from "gsap";
// import GridGallery from "@/components/GridGallery.vue";
import ExpansionPanel from "@/components/ExpansionPanel.vue";
import { firebase, db } from "@/firebase";
export default {
  name: "About",
  metaInfo: {
    title: "About",
    meta: [
      {
        property: "og:title",
        content: "About",
      },
      {
        property: "twitter:title",
        content: "About",
      },
    ],
    // tags: ["Karjat", "Experience", "Places"],
  },
  data() {
    return {
      showLabels: false,
      windowWidth: 0,
      name: "",
      email: "",
      phone: "",
      interest: "",
      comment: "",
      submitStatus: 0,
      submitResponse: "",
      interestOptions: [
        { text: "Interested in...", value: "" },
        { text: "5 Bedroom Villa", value: "5 Bedroom Villa" },
        { text: "3 Bedroom Cottage", value: "3 Bedroom Cottage" },
        { text: "5 Bedroom + 3 Bedroom", value: "5 Bedroom + 3 Bedroom" },
        { text: "Events & More", value: "Events & More" },
        { text: "Something Else", value: "Something Else" },
      ],
      gallery: [
        {
          id: 1,
          title: "",
          path: require("@/assets/5bhk.jpg"),
        },
        {
          id: 2,
          title: "",
          path: require("@/assets/sample.jpg"),
        },
        {
          id: 3,
          title: "",
          path: require("@/assets/riverside.jpg"),
        },
        {
          id: 4,
          title: "",
          path: require("@/assets/driveaway.jpg"),
        },
        {
          id: 5,
          title: "",
          path: require("@/assets/3bhk.jpg"),
        },
        {
          id: 6,
          title: "",
          path: require("@/assets/family.jpg"),
        },
      ],
    };
  },
  components: {
    // GridGallery,
    ExpansionPanel,
  },
  watch: {
    windowWidth(newWidth) {
      //console.log("windowWidth", newWidth, oldWidth);
      this.showLabels = newWidth < 600 ? false : true;
    },
  },
  methods: {
    async sendContactForm() {
      if (this.name.length < 3) {
        this.submitResponse = "Name should have a minimum of 3 characters.";
        this.shakeElement("#name");
      } else if (!this.validEmail(this.email)) {
        this.submitResponse = "Looks like an invalid email address.";
        this.shakeElement("#email");
      } else if (this.phone.length < 10) {
        this.submitResponse =
          "Looks like an invalid mobile number. (Min 10 digits)";
        this.shakeElement("#phone");
      } else if (this.interest === "") {
        this.submitResponse = "Tell us what are you interested in.";
        this.shakeElement("#interest");
      }
      // else if (this.comment.length < 10) {
      //   this.submitResponse = "Comment should have a minimum of 10 characters";
      // }
      else {
        this.submitStatus = 1;
        this.submitResponse = "Submitting. Please wait...";
        const docData = {
          name: this.name,
          email: this.email,
          phone: this.phone,
          interest: this.interest,
          comment: this.comment,
          dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
        };
        const docRef = db.collection("ContactForm");
        docRef
          .add(docData)
          .then((_docRef) => {
            this.submitStatus = -1;
            this.submitResponse =
              "Thank you for writing in to us. We shall reply soon...";
            console.log("Doc Id:" + _docRef.id);
          })
          .catch((error) => {
            this.submitStatus = 0;
            this.submitResponse = error;
            console.log("Error:", error);
          });
        console.log("this.submitResponse", this.submitResponse);
      }
    },
    shakeElement(element) {
      // const element = this.$refs.responseElement;
      // console.log("shakeElement", element);
      gsap.from(element, { x: "-=20px", duration: 0.5, ease: "elastic" });
      // gsap.to(element, { borderColor: "red" });
    },
    // resetShakeElement(element) {
    //   gsap.to(element, { clearProps: "all" });
    // },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    // initFields() {
    //   this.resetShakeElement(this.$refs.inputName);
    //   this.$refs.inputName.addEventListener("focus", this.resetShakeElement);
    // },
  },
  mounted() {
    // this.initFields();
    window.addEventListener("resize", this.onResize);
    this.windowWidth = window.innerWidth;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped lang="scss">
._about {
  ._section__about--intro {
    @extend ._section__custom--overlap;
    ._media {
      display: grid;
      grid-template-rows: 1fr 6fr 5fr;
      grid-template-columns: 0.5fr 1.5fr 1fr 3fr;
      ._picture {
        &--1 {
          @extend ._picture;
          grid-area: 2/2/3/4;
          z-index: 1;
        }
        &--2 {
          @extend ._picture;
          grid-area: 1/3/-1/-1;
        }
      }
    }
  }
  ._section__about--place {
    @extend ._section__custom--overlap;
  }
  ._about__expansion {
    @extend ._section__custom;
    margin-bottom: 0;
  }
  ._section__about--experiences {
    @extend ._section;
    height: inherit;
  }
  ._section__about--places {
    @extend ._section;
  }
  ._section__about--form {
    @extend ._section__custom;
    ._card__custom {
      @extend ._card__auto;
      @extend ._card--raised;
      display: flex;
      flex-direction: column;
      background-color: $ochre;
      .btn--light {
        @extend ._subtitle;
        margin: 0;
        align-self: flex-start;
        color: $ochre;
        &:hover {
          color: $white;
        }
      }
      ._response {
        @extend ._subtitle;
        color: $white;
        margin: 0px 0 20px;
      }
    }
  }
}
@media screen and (max-width: 400px) {
  ._about {
    ._section__about--intro {
      ._media {
        ._picture {
          &--1 {
            grid-area: 2/1/3/4;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 600px) {
  ._about {
  }
}
@media screen and (min-width: 1200px) {
  ._about {
    ._about__expansion {
      display: flex;
      gap: 60px;
      // We cannot have a max-height, otherwise, the panel won't expand :P
      // max-height: 500px;
      overflow: hidden;
    }
    ._section__about--experiences {
      margin-bottom: 90px;
    }
    ._section__about--places {
      margin-bottom: 90px;
    }
    ._section__about--form {
      // padding: 0;
      ._card__custom {
        display: grid;
        grid-template-rows: min-content auto;
        grid-template-columns: 30% 30% auto;
        // justify-items: start;
        row-gap: 20px;
        column-gap: 30px;
        ._title--light {
          padding: 20px 0;
          grid-area: 1/1/2/-1;
        }
        ._phone {
          grid-area: 3/1/4/2;
        }
        ._interest {
          grid-area: 3/2/4/3;
        }
        ._comment {
          grid-area: 2/3/4/4;
          display: grid;
          grid-template-rows: min-content auto;
        }
        .btn--light {
          grid-area: 4/1/5/4;
          align-self: center;
          justify-self: start;
        }
        ._response {
          grid-area: 4/1/5/4;
          place-self: center end;
          padding-left: 30px;
          margin: 0;
        }
        ._divider {
          width: 1px;
          height: 100%;
          // background-color: $white;
          border-right: 1px solid $white;
          justify-self: center;
        }
      }
    }
  }
}
@media screen and (min-width: 1440px) {
  ._about {
    ._section__about--form {
      ._card__custom {
        margin: 0;
      }
    }
  }
}
</style>